<template>
  <div class="page_container">
    <div class="img_box">
      <img :src="imageUrl"/>
    </div>
    <!-- 文章列表 -->
    <div class="article_list">
      <ul class="lists">
        <li class="list_item" v-for="(item, index) in specialList" :key="index">
          <div class="cover">
            <img :src="item.imageUrl"/>
          </div>
          <div class="content_container">
            <div class="title" @click="skipDetails(item)">{{ item.title }}</div>
            <div class="content">{{ item.introduction }}</div>
            <div class="tools">
              <div class="author">
                <img v-if="item.iconUrl" :src="item.iconUrl"/>
                <img v-else src="@/assets/image/authorImg.png">
                <span class="nick_name">{{ item.authorName }}</span>
              </div>
              <ul class="tags">
                <li v-for="(item,index) in item.labelList.slice(0, 4)" :key="index" @click="shipTagList(item.ltName)">
                  {{ item.ltName }}
                </li>
              </ul>
              <span class="time_tip">{{ item.relaseTime }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let timer;

export default {
  name: "index",
  data: () => ({
    specialList: [],
    imageUrl: '',
    pageInfo: {
      currentPage: 1,
      currentPageSize: 10,
      totalPage: 100,
    },
    spId: undefined,
  }),
  created() {
    let spId = this.$route.params.spId
    this.spId = spId
    let params = {
      spId,
      currentPage: 1,
      currentPageSize: 10
    }
    this.getList(params)
  },
  mounted() {
    window.onscroll = () => {
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight - 300) {
        let params = {
          spId: this.spId,
          currentPageSize: 10,
          currentPage: this.pageInfo.currentPage + 1
        }
        this.throttle(params)
      }
    };
  },
  methods: {

    getList(params) {
      if (this.pageInfo.totalPage < params.currentPage) return
      this.axios.get('/sys/special/article-list', {params}).then(res => {
        this.imageUrl = res.imageUrl
        let tempResult = this.specialList.slice()
        this.specialList = tempResult.concat(res.page.list)
        this.pageInfo = {
          totalPage: res.page.totalPage,
          currentPageSize: res.page.pageSize,
          currentPage: res.page.currPage
        }
      })
    },
    throttle(params) {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        this.getList(params)
        timer = undefined;
      }, 400);
    },
    skipDetails(item) {
      this.$router.push('/article-detail/' + item.atUuid)
    },
    shipTagList(name) {
      this.$router.push({name: 'TagList', params: {tagType: name}})
    }
  },
};
</script>

<style lang="scss" scoped>
.page_container {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 40px;
  margin-bottom: 40px;

  .img_box {
    width: 100%;
    height: 340px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .article_list {
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;

    .lists {
      .list_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #efefef;
        cursor: pointer;


        .cover {
          width: 140px;
          height: 100px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .content_container {
          flex: 1;
          width: 0;
          position: relative;
          top: -5px;


          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 41px;
            color: #000000;
            //margin-bottom: 10px;
            &:hover {
              color: #3E35E7;
            }
          }

          .content {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 14px;
            line-height: 30px;
            color: #000000;
            min-height: 20px;
          }

          .tools {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;

            .author {
              display: flex;
              align-items: center;

              img {
                height: 30px;
                width: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 5px;
              }

              .nick_name {
                width: 70px;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .tags {
              flex: 1;
              display: flex;

              li {
                height: 24px;
                line-height: 24px;
                box-sizing: border-box;
                text-align: center;
                background: #ECECEC;
                font-size: 12px;
                color: #3E35E7;
                border-radius: 12px;
                padding: 0 8px;

                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
            }

            @media (min-width: 1280px) {
              .tags {
                li {
                  &:not(:last-child) {
                    margin-right: 20px;
                  }
                }
              }
            }

            .show-time {
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
